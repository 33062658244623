@import '../core-globals.scss';
.GlobalError {
    z-index: 2;
    position: absolute;
    top: 84px;
    right: 24px;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: right;
    font-family: $nikeFont;
    max-width: 300px;
    &__item {
        border: 1px solid $errorColor;
        color: $secondaryColor;
        background-color: $secondaryColor;
        box-shadow: 0px 0px 3px rgba($primaryColor, 0.2), 0px 0px 12px 12px rgba($secondaryColor, 0.8);
        margin-bottom: 12px;
        display: grid;
        grid-template-columns: auto 28px;
        grid-template-areas:
            'title clear'
            'message message';
        h3 {
            margin: 0;
            background-color: $errorColor;
            padding: 6px;
            font-size: 1.2em;
            grid-area: title;
        }
        p {
            margin: 0;
            padding: 8px;
            color: $primaryColor;
            text-align: left;
            grid-area: message;
        }
        &--animation {
            &-enter {
                opacity: 0;
                transition: opacity 500ms ease-out;
                &-active {
                    opacity: 1;
                }
            }
            &-exit {
                opacity: 1;
                transition: opacity 200ms ease-out;
                &-active {
                    opacity: 0;
                }
            }
        }
    }
    &__clear {
        padding: 8px;
        background-color: $errorColor;
        cursor: pointer;
        color: $secondaryColor;
        grid-area: clear;
        &:hover {
            color: $lightPrimaryColor;
        }
    }
}
