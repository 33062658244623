@import '../core-globals.scss';
.ReactModal__Content {
    max-width: 650px;
}
.ReactModalPortal {
    .modal-header {
        flex-direction: column;
        padding: 0;
        padding-bottom: 15px;

        .modal-title {
            margin: 0;
            margin-bottom: 10px;
            text-transform: uppercase;
        }

        .modal-subtitle {
            font-style: normal;
            font-weight: 400;
            font-family: 'Trade Gothic for Nike 365 BdCn';
            text-transform: uppercase;
            font-size: 16px;
            margin-bottom: 10px;
            display: block;
        }

        .modal-close {
            position: absolute;
            right: 25px;
            top: 25px;
        }
    }

    .modal-body {
        padding: 0;

        .modal-text {
            font-size: 16px;
            margin: 20px 0;
            display: block;
        }

        .modal-children {
            font-size: 16px;
            margin: 20px 0;
            display: block;

            .update-flowdate--actions {
                button {
                    flex: 1;
                    margin-left: 0;
                    &:first-child {
                        margin-right: 10px;
                    }
                    &:only-child {
                        margin-right: 0;
                    }
                }
            }
            .update-flowdate--form {
                input {
                    margin-left: 0px;
                }
            }
        }

        .modal-button-container {
            padding-top: 25px;
            display: flex;
            flex-direction: row;

            button {
                &:first-child {
                    margin-right: 10px;
                }
                &:only-child {
                    margin-right: 0;
                }
                flex: 1;
            }
        }
    }
}
