@import './core-globals.scss';

@font-face {
    font-family: 'Trade Gothic for Nike 365 BdCn';
    src: url(../assets/fonts/TradeGothicforNike365-BdCn.eot);
    src: url(../assets/fonts/TradeGothicforNike365-BdCn.ttf) format('truetype');
}

html,
body,
#root {
    margin: 0;
    padding: 0;
    height: 100%;
    line-height: 1em;
    font-family: $regularFont;
    font-size: 14px;
}

#root .route-container {
    height: 100%;
    & > div {
        overflow-x: hidden;
    }
}

h1,
h2,
h3,
h4,
th,
button {
    font-family: $nikeFont;
    line-height: 1.4em;
    background-color: transparent;
}

h1 {
    color: $primaryColor;
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 30px;
}

h2 {
    color: $primaryColor;
    font-size: 28px;
}

h3 {
    color: inherit;
    font-size: 20px;
    margin-top: 10px;
}
h4 {
    color: inherit;
    font-size: 1.2em;
    margin-top: 10px;
}
h1,
h2,
h3,
h4 {
    &.warning {
        color: $warningColor;
    }
}

p {
    line-height: 1.6em;
}

ul,
ol {
    line-height: 1.4em;
}

a {
    color: $primaryColor;
    text-decoration: underline;
    &:hover {
        color: $tertiaryColor;
    }
}

div[role='dialog'] {
    background-color: rgba(0, 0, 0, 0.7);
}

.nopadding {
    padding: 0 !important;
}

.centered {
    text-align: center;
}

section {
    margin-bottom: 32px;
}

@media all and (max-width: $break-xlarge) {
    h1 {
        font-size: 26px;
    }
    h2 {
        font-size: 18px;
    }
}

:target {
    display: block;
    position: relative;
    top: -60px;
    visibility: hidden;
}
