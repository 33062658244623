@import "../core-globals.scss";

.checkbox-container {
    display: flex;
    position: relative;
    padding-left: 25px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 22px;
    justify-content: flex-start;
    align-items: center;
    min-height: 17px;
    user-select: none;

    .checklabel {
        font-style: normal;
        font-size: 14px;
        font-weight: 400;
        font-family: OneNikeCurrency, Helvetica, Arial, sans-serif;
        line-height: 1em;
        font-family: Helvetica, Arial, sans-serif;
    }

    /* Hide the browser's default checkbox */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        /* When the checkbox is checked, add a blue background */
        &:checked ~ .checkmark {
            background-color: #fff;

            /* Show the checkmark when checked */
            &:after {
                display: block;
            }
        }
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
        width: 17px;
        height: 17px;
        border: 1px solid #e5e5e5;
        border-radius: 2px;

        &:after {
            /* Create the checkmark/indicator (hidden when not checked) */
            content: "";
            position: absolute;
            display: none;

            /* Style the checkmark/indicator */
            left: 6px;
            top: 3px;
            width: 4px;
            height: 8px;
            border: solid #000;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    /* On mouse-over, add a grey background color */
    &:hover {
        input {
            ~ .checkmark {
                background-color: rgb(247, 231, 231);
            }
        }
    }
}
