@import '../core-globals.scss';

.view-shipplan {
    display: -ms-grid;
    display: grid;
    width: 100vw;
    -ms-grid-columns: 2;
    grid-template-areas: 'filters table';
    &__sidebar {
        grid-area: filters;
        -ms-grid-column: 1;
        background: #fff;
        height: calc(100vh - 90px);
        left: 9px;
        width: 64px;
        z-index: 9;
        font-size: 13px;
        -webkit-tap-highlight-color: transparent;
        -ms-overflow-style: scrollbar;
        overflow-y: scroll;
    }
    &__table {
        grid-area: table;
        width: calc(100vw - 64px);
        -ms-grid-column: 2;
        overflow: hidden;
        margin-top: -3px;
    }
    &--sidebar-open {
        .view-shipplan__sidebar {
            width: 320px;
        }
        .view-shipplan__table {
            width: calc(100vw - 320px);
        }
    }
}
.view-shipplan,
.view-material {
    &__no-results {
        line-height: 1.6em;
        h3,
        p {
            text-align: center;
        }
    }

    .ReactTable {
        border: none;
        .rt-noData {
            pointer-events: initial;
            box-shadow: 0 0 10px rgba($primaryColor, 0.2);
        }
        .rt-table {
            -ms-overflow-style: -ms-autohiding-scrollbar;
        }
        .rt-tbody {
            overflow-y: initial;
            overflow-x: hidden !important;
        }
        .rt-tr-group {
            max-height: 30px;
        }
        .rt-resizable-header-content {
            font-style: normal;
            font-weight: 400;
            font-family: 'Trade Gothic for Nike 365 BdCn';
            text-transform: uppercase;
            line-height: 21px;
        }
        .rt-resizer {
            width: 18px;
            right: -9px;
        }
        .rt-td {
            overflow: hidden;
            border-left: 1px solid $borderColor;
            &--first {
                border-left: 1px solid rgba($primaryColor, 0.2);
            }
            &--last {
                span {
                    padding-right: 16px;
                }
            }
            &--no-padding,
            &--no-padding span {
                padding: 0;
                &.rt-td--no-override {
                    display: block;
                    padding: 7px 34px 7px 5px;
                }
            }
            &--overflow-visible {
                overflow: visible;
            }
            &--narrow {
                font-family: $nikeFont;
            }
            &--center {
                text-align: center;
            }
        }
        .rt-th {
            font-size: 13px;
            line-height: 1.6em;
            font-family: $nikeFont;
            text-transform: uppercase;
            padding-bottom: 0;
            &--custom-header {
                text-align: left;
                a {
                    text-decoration: none;
                }
            }
            &--flow {
                &--first {
                    border-left: 1px solid rgba($primaryColor, 0.2);
                }
            }
        }
        .-headerGroups {
            .rt-th {
                font-size: 15px;
            }
        }

        .-sort-desc,
        .-sort-asc {
            box-shadow: none !important;
            &:before {
                position: absolute;
                top: 5px;
                right: 2px;
            }
        }

        .-sort-asc {
            &:before {
                content: '▲';
            }
        }
        .-sort-desc {
            &:before {
                content: '▼';
            }
        }
    }
}
