@import '../core-globals.scss';

.view-material {
    display: -ms-grid;
    display: grid;
    width: 100vw;
    -ms-grid-columns: 2;
    grid-template-areas: 'filters table';
    &__sidebar {
        grid-area: filters;
        -ms-grid-column: 1;
        background: #fff;
        height: calc(100vh - 82px);
        left: 9px;
        width: 64px;
        padding: 4px;
        z-index: 1;
        font-size: 13px;
        -webkit-tap-highlight-color: transparent;
        -ms-overflow-style: scrollbar;
        overflow-y: scroll;
    }
    &__tables {
        grid-area: table;
        width: calc(100vw - 64px);
        -ms-grid-column: 2;
        margin-top: -3px;
        overflow-x: scroll;
        position: relative;
    }
    &__table {
        &-fixed {
            background-color: $secondaryColor;
            z-index: 1;
            position: absolute;
        }
        &-body {
            height: calc(100vh - 72px);
            overflow-x: hidden;
            padding-top: 80px;
        }
    }
}
