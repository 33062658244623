@import '../core-globals.scss';

.PartnerProposals {
    &__upload {
        height: 100px;
        margin-bottom: 16px;
    }
    &__success {
        color: $successColor;
    }
}
