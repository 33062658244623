@import '../core-globals.scss';

.InvalidOverrides {
    &__list {
        list-style: none;
        padding: 8px;
        line-height: 1.6em;
        max-height: 200px;
        overflow-y: scroll;
        border: 1px solid $borderColor;
        font-size: 0.8em;
    }

    &__material {
        float: left;
        font-family: 'Courier New', Courier, monospace;
        margin-right: 12px;
        svg {
            color: $warningColor;
        }
    }
}
