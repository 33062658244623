@import "../core-globals.scss";

.unauthorized {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px;
}

.centered-loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
