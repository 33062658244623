@import '../core-globals.scss';

.Documentation {
    &__navigation {
        font-family: $nikeFont;
        font-size: 1.2em;
        line-height: 1.4em;
        a {
            display: block;
            text-decoration: none;
            padding: 0 12px;
            margin-bottom: 12px;
            &.active {
                color: $tertiaryColor;
            }
        }
    }
}
