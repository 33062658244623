@import '../core-globals.scss';

.ExchangeRates {
    h1 {
        .svg-loader {
            display: inline;
            margin-left: 8px;
        }
    }
    &__error {
        color: $errorColor;
    }
}
