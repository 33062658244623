@import '../core-globals.scss';

.GroupBy {
    &__list {
        padding-left: 23px;
        list-style-type: square;
        margin-top: 8px;
    }
    &__listitem {
        &--active {
            .GroupBy__button {
                font-weight: bold;
            }
        }
    }
    &__button {
        border: none;
        width: 100%;
        padding: 2px 7px;
        font-size: 1.2em;
        cursor: pointer;
        text-align: left;
        text-transform: capitalize;
        font-family: Helvetica;
        &:hover,
        &:focus {
            font-weight: bold;
            outline: none;
        }
    }
    h3 {
        margin-top: 0;
    }
}
