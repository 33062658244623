@import '../core-globals.scss';

.Override {
    display: grid;
    max-width: 100%;
    position: relative;
    &--material {
        grid-template-columns: 1fr 20px;
    }
    &--materialstore {
        grid-template-columns: 1fr 1fr 20px;
    }
    input {
        border: 1px solid transparent;
        background-color: transparent;
        height: 27px;
        padding: 7px 0 7px 5px;
        text-align: right;
    }
    svg {
        margin-left: 3px;
    }
    &__proposal {
        color: $lightPrimaryColor;
        &--changed {
            font-weight: bold;
            color: $successColor;
        }
    }

    &__overlay {
        position: absolute;
        z-index: 1;
        top: 1px;
        left: 95%;
        border: 1px solid $lightPrimaryColor;
        display: inline-block;
        padding: 4px 8px 4px 4px;
        height: 26px;
        font-size: 0.9em;
        background-color: white;
        box-shadow: 0px 0px 4px rgba($primaryColor, 0.4);
    }

    &__error {
        color: $errorColor;
        border-color: $errorColor;
    }

    &__warning {
        color: $warningColor;
        border-color: $warningColor;
    }

    &--editing {
        input {
            opacity: 1;
            background-color: transparent;
            outline: none;
        }
    }
    &--disabled {
        input {
            color: $lightPrimaryColor;
        }
    }
}
