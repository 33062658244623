@import '../core-globals.scss';

.Button {
    background-color: $buttonBackground;
    color: $buttonColor;
    display: inline-flex;
    font-family: $nikeFont;
    font-size: 14px;
    line-height: inherit;
    max-height: 44px;
    min-height: 32px;
    min-width: 152px;
    padding-left: 32px;
    padding-right: 32px;
    text-transform: uppercase;
    width: auto;
    height: 32px;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    vertical-align: middle;
    border-radius: 0;
    border: none;
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    margin-bottom: 10px;
    &:focus,
    &:hover {
        text-decoration: inherit;
        color: $buttonColor;
    }

    &:hover {
        background-color: $buttonBackgroundHover;
        transition-duration: 0.3s !important;
    }
    &:focus {
        outline: none;
    }

    &:disabled {
        background: darken(#e5e5e5, 10%);
    }

    .svg-loader {
        margin-left: 8px;
        margin-right: 0;
    }

    &__inverted {
        background: #fff;
        color: #000;
        border: 1px solid #000;
        &.Button-active {
            background-color: $primaryColor;
            color: #fff;
        }
        &:hover {
            color: #fff;
            border: 1px solid transparent;
        }
        &:focus {
            color: #000;
        }
        &:disabled {
            background: darken(#fff, 10%);
        }
    }

    &.danger {
        &:hover {
            background-color: #e84e4e;
        }
    }

    &-done {
        background-color: $buttonBackgroundHover;
        transition-duration: 0.3s !important;
    }

    &__inner {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 0;
        position: absolute;
        overflow: hidden;
        border-radius: inherit;
        pointer-events: none;
    }
    &-large {
        height: 44px;
    }

    &-fullWidth {
        width: 100%;
    }

    &-progress,
    &-progress:disabled {
        cursor: progress;
    }
}
