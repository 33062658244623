@import '../core-globals.scss';

$stepIndicatorSize: 64px;
$borderSize: 2px;
$stepSpacing: 12px;
$titleSize: 24px;

.stepper {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    .step {
        margin: 0;
        &__header {
            display: flex;
            align-items: center;
        }
        &__indicator {
            display: flex;
            margin-right: $stepSpacing * 2;
            flex-shrink: 0;
            width: $stepIndicatorSize;
            height: $stepIndicatorSize;
        }
        &__title {
            font-weight: bold;
            width: 100%;
            font-family: $nikeFont;
            color: inherit;
            text-transform: uppercase;
            text-decoration: inherit;
            font-size: 1.4em;
        }
        &__content {
            border-left-width: $borderSize;
            border-left-style: solid;
            border-left-color: $borderColor;
            margin-top: $stepSpacing;
            margin-bottom: $stepSpacing;
            margin-left: $stepIndicatorSize / 2;

            padding-left: ($stepIndicatorSize / 2) - $borderSize + ($stepSpacing * 2);
            padding-right: $stepSpacing;
            min-height: 10px;
            // max-height: 200px;
            // transition: max-height 1s ease-out;
            // overflow: hidden;
        }
        &--last .step__content {
            border-left-color: transparent;
        }
    }
}
