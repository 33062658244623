@import '../core-globals.scss';

.SyncPrices {
    padding: 8px 12px;
    line-height: 2em;
    border-radius: 5px;
    border: 1px solid $borderColor;
    background-color: $backgroundColor;
    font-family: $nikeFont;
    text-transform: uppercase;
    &__name {
        display: inline-block;
        margin-left: 8px;
    }
    &__date {
        color: $lightPrimaryColor;
        margin-left: 8px;
    }
    .Button {
        margin-bottom: 0;
        line-height: 1em;
    }
    &__error {
        color: $errorColor;
    }
}
