@import '../core-globals.scss';

.upload-file {
    width: 100%;
    height: 100%;
    padding: 12px 0;
    line-height: 1.8em;
    font-size: 1.4em;
    border: 2px dashed $borderColor;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .svg-loader {
        display: inline-block;
    }
    font-family: $nikeFont;
    &--reupload {
        font-size: 1rem;
        position: absolute;
        top: 7px;
        right: 13px;
        border: none;
        cursor: pointer;
        background-color: transparent;
        margin-right: -8px;
        &:hover {
            color: $errorColor;
        }
    }
    &--uploading {
        color: $tertiaryColor;
    }
    &--success,
    &--error {
        text-align: center;
        font-size: 1.2em;
    }
    &--error {
        padding: 24px 0;
        border-color: $errorColor;
        color: $errorColor;
    }
    &--success {
        color: $successColor;
    }
}
