@import '../core-globals.scss';

.UnitToggle {
    margin-bottom: 24px;
    &--orientation {
        &-horizontal {
            section {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                fieldset {
                    legend {
                        font-size: 1em;
                        margin-bottom: 4px;
                        font-family: $nikeFont;
                    }
                }
            }
        }
        &-vertical {
            fieldset {
                margin-bottom: 8px;
                legend {
                    display: none;
                }
            }
        }
    }
}
