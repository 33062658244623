@import '../core-globals.scss';

.app-header {
    height: 48px;
    box-shadow: inset 0 -1px 0 0 $borderColor;
    position: fixed;
    background-color: $secondaryColor;
    z-index: 2;
    width: 100%;
    @media only screen and (min-width: $break-large) {
        height: 60px;
    }
    .logo-container {
        position: absolute;
        left: 0;
        display: flex;
        align-items: center;
        align-content: center;
        height: 100%;
        img {
            width: 70px;
            margin-left: 32px;
            margin-right: 20px;
            @media only screen and (min-width: $break-large) {
                width: 60px;
            }
        }
        .app-name {
            margin: 0;
            letter-spacing: -1px;
            text-transform: uppercase;
            font-size: 24px;
            color: $primaryColor;
            font-family: 'Trade Gothic for Nike 365 BdCn';
            @media only screen and (min-width: $break-large) {
                font-size: 32px;
            }
        }
    }
    .navigation {
        text-align: right;
        a {
            display: inline-block;
            color: $lightPrimaryColor;
            text-decoration: none;
            font-family: $nikeFont;
            text-transform: uppercase;
            padding: 16px 8px;
            font-size: 12px;
            @media only screen and (min-width: $break-small) {
                padding: 16px;
            }
            @media only screen and (min-width: $break-large) {
                padding: 22px;
            }
            &:active,
            &:hover,
            &.active {
                text-decoration: none;
                color: $primaryColor;
            }
        }
        @media only screen and (min-width: $break-large) {
            text-align: center;
            a {
                font-size: 16px;
            }
        }
    }
}
