@import "../core-globals.scss";

.filter-group {
    display: flex;
    flex-direction: column;
    .filter-group-spinner {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 200px);
        flex-grow: 1;
    }
}
.filter-group-wrapper {
    &--loading {
        pointer-events: none;
        position: relative;
        .filter-group {
            opacity: 0.3;
        }
    }
    .svg-loader {
        position: absolute;
        top: 100px;
        left: 50%;
        margin-left: -60px;
        z-index: 2;
    }
}

.ReactModalPortal {
    .close-button {
        margin-left: 10px;
    }
}
