@import '../core-globals.scss';

.ViewShipplanSidebar {
    &__header {
        border-bottom: 1px solid $borderColor;
        button {
            display: inline-block;
        }
        h2 {
            margin-left: 8px;
            display: inline-block;
            font-size: 1.8em;
            margin: 0;
        }
    }
    &__content {
        padding: 24px;
    }
    &__toggle {
        border: none;
        width: 48px;
        height: 48px;
        cursor: pointer;
        font-size: 2em;
        &:focus {
            outline: none;
        }
    }
}
