$primaryColor: #000000;
$secondaryColor: #ffffff;
$successColor: rgb(0, 166, 100);
$errorColor: rgb(215, 29, 79);
$warningColor: rgb(255, 138, 29);
$tertiaryColor: rgb(2, 127, 168);
$lightPrimaryColor: rgba($primaryColor, 0.4);

$backgroundColor: #f7f7f7;
$borderColor: #e5e5e5;

$buttonBackground: rgb(34, 34, 34);
$buttonBackgroundHover: rgb(52, 152, 219);
$buttonColor: $secondaryColor;

$break-xlarge: 1280px;
$break-large: 991px;
$break-medium: 767px;
$break-small: 575px;

$nikeFont: 'Trade Gothic for Nike 365 BdCn';
$regularFont: Helvetica, Arial, sans-serif;

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
