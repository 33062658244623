@import "../core-globals.scss";

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}
.filter-input-inner-wrapper {
    font-size: 12px;
    line-height: 18px;
    width: 100%;
    height: 36px;
    display: inline-block;
    position: relative;
    background-color: transparent;
    font-family: Helvetica, "Helvetica Neue", Roboto, sans-serif;
    transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    cursor: auto;
    color: $primaryColor;
    fill: $primaryColor;
    padding-bottom: 4px;
    margin-bottom: 20px;

    input {
        padding: 0px;
        position: relative;
        width: 100%;
        border: none;
        outline: none;
        color: rgba(0, 0, 0, 0.87);
        cursor: inherit;
        font-style: normal;
        font-weight: 300;
        font-family: "Trade Gothic for Nike 365 BdCn";
        font-size: 13px;
        opacity: 1;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        height: 100%;
        text-transform: uppercase;

        @include placeholder {
            opacity: 1;
            color: rgb(204, 204, 204);
            transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
            bottom: 12px;
            font-size: 13px;
            line-height: 18px;
        }
    }

    hr {
        border-top: none rgb(224, 224, 224);
        border-left: none rgb(224, 224, 224);
        border-right: none rgb(224, 224, 224);
        border-bottom: 1px solid rgb(204, 204, 204);
        bottom: 8px;
        box-sizing: content-box;
        margin: 0px;
        position: absolute;
        width: 100%;
    }
}
