@import '../core-globals.scss';

.InvalidPartnerProposal {
    &__list {
        list-style: none;
        padding: 8px;
        line-height: 1.6em;
        max-height: 200px;
        overflow-y: scroll;
        border: 1px solid $borderColor;
        font-size: 0.8em;
    }

    &__error {
        font-family: 'Courier New', Courier, monospace;
        white-space: pre-wrap;

        &:not(:first-child) {
            margin-top: 20px;
        }

        svg {
            color: $warningColor;
        }
    }
}
