@import '../core-globals.scss';

.file-drop {
    width: 100%;
    height: 100%;
    line-height: 1.8em;
    color: $lightPrimaryColor;
    position: relative;
    &__reupload {
        position: absolute;
        top: 9px;
        right: 13px;
        // z-index: 1;
        border: none;
        // float: right;
        cursor: pointer;
        background-color: transparent;
        margin-right: -8px;
        &:hover {
            color: $errorColor;
        }
    }
    .dropzone {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border: dashed 2px $borderColor;
        outline: none;
        transition: border-color 0.3s ease, color 0.3s ease;
        padding: 12px;
        flex-direction: column;
        &__title {
            font-family: $nikeFont;
            font-size: 1.2em;
            text-transform: uppercase;
        }
        &__browse {
            font-weight: bold;
        }
        cursor: pointer;
        &:hover {
            .dropzone__browse {
                color: $tertiaryColor;
            }
        }
        &:focus,
        &:active {
            border: dashed 2px $lightPrimaryColor;
        }
        &--isActive {
            color: $tertiaryColor;
            border-color: $tertiaryColor;
        }
        &--isRejected {
            color: $errorColor;
            border-color: $errorColor;
        }
    }
}
