@import '../core-globals.scss';

.Toggle {
    display: inline-block;
    &--orientation {
        &-horizontal {
            .Toggle__button + .Toggle__button {
                border-left: none;
            }
            & + .Toggle {
                margin-left: 8px;
            }
        }
    }
    &--orientation {
        &-vertical {
            .Toggle__button + .Toggle__button {
                border-top: none;
            }
            & + .Toggle {
                margin-top: 8px;
            }
        }
    }
    &__button {
        font-size: 18px;
        width: 40px;
        height: 40px;
        border-top: 1px solid $borderColor;
        border-right: 1px solid $borderColor;
        border-bottom: 1px solid $borderColor;
        border-left: 1px solid $borderColor;

        padding-left: 1px;
        padding: 0;
        cursor: pointer;
        &:hover {
            background-color: $backgroundColor;
        }
        &:focus {
            outline: none;
        }
        &:disabled {
            opacity: 0.5;
        }
        &--active {
            background-color: $primaryColor;
            color: $secondaryColor;
            border: 1px solid $primaryColor;
            &:hover {
                background-color: $primaryColor;
            }
        }
    }
}
