@import '../core-globals.scss';

.OverrideLock {
    cursor: pointer;
    outline: none;
    display: inline-block;
    position: relative;
    margin: 4px 0;
    width: 20px;
    &:active {
        color: $tertiaryColor;
    }
    .svg-loader {
        margin: 2px 0 0 0;
    }
    .OverrideLock-icon-unlock,
    .OverrideLock-icon-lock {
        position: absolute;
        top: 3px;
    }
    .OverrideLock-icon-lock,
    .OverrideLock-icon-unlock {
        visibility: hidden;
    }

    &--inactive {
        color: rgba($primaryColor, 0.2);
    }
    &--submit {
        .OverrideLock-icon-unlock {
            visibility: visible;
        }
        &:hover {
            .OverrideLock-icon-lock {
                visibility: visible;
            }
        }
        &.OverrideLock--has-override {
            .OverrideLock-icon-unlock {
                visibility: hidden;
            }
            .OverrideLock-icon-lock {
                visibility: visible;
            }
        }
    }
    &--delete {
        .OverrideLock-icon-lock {
            visibility: visible;
        }
        &:hover {
            .OverrideLock-icon-lock {
                visibility: hidden;
            }
            .OverrideLock-icon-unlock {
                visibility: visible;
            }
        }
    }
}
