@import '../core-globals.scss';

.FileInfo {
    padding: 8px 12px;
    line-height: 2em;
    border-radius: 5px;
    border: 1px solid $borderColor;
    background-color: $backgroundColor;
    font-family: $nikeFont;
    text-transform: uppercase;
    &__name {
        display: inline-block;
        margin-left: 8px;
    }
    &__created {
        color: $lightPrimaryColor;
        margin-left: 8px;
    }
    &__quantity {
        font-size: 1.2em;
        color: $lightPrimaryColor;
    }
    &__reupload {
        border: none;
        float: right;
        cursor: pointer;
        background-color: transparent;
        margin-right: -8px;
        &:hover {
            color: $errorColor;
        }
    }
}
