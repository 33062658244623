@import '../core-globals.scss';

.PartnerProposalsList {
    list-style: none;
    padding: 0;
    font-family: $nikeFont;
    text-transform: uppercase;
    &__item {
        font-size: 1.2em;
        color: $lightPrimaryColor;
        &--completed {
            color: $successColor;
        }
        &--failed {
            color: $errorColor;
        }
        border-bottom: 1px solid $borderColor;
        padding: 12px 8px;

        &:nth-child(odd) {
            background-color: rgba($primaryColor, 0.02);
        }
        &:nth-child(even) {
            background-color: #fff;
        }
    }
    &__updated {
        color: $lightPrimaryColor;
    }
}
