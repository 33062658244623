@import '../core-globals.scss';

.layout {
    min-height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

    .content {
        padding-top: 60px;
    }
}
